import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import "./nav.css";
import DNA from "../icons/dashboard.svg";
import Bounty from "../icons/lottery.svg";
import FundTransfer from "../icons/fund-transfer.svg";
import Referral from "../icons/referral.svg";
import Report from "../icons/report.svg";
import Shopping from "../icons/shopping.svg";
import Withdrawal from "../icons/withdrawal.svg";
import Support from "../icons/support.svg";
import { PATH_USER } from "src/routes/paths";
import { useLocation } from "react-router";

const UserVerticalNavbar = ({ config, isCollapse }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference to the menu container

  // Function to toggle menu open/close
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  window.onscroll = function () {
    addFixedClass();
  };

  function addFixedClass() {
    const verticalMenu = document.querySelector(".vertical-menu");
    if (window.scrollY > 130) {
      verticalMenu.classList.add("stickyNav");
    } else {
      verticalMenu.classList.remove("stickyNav");
    }
  }
  const location = useLocation();
  return (
    <Box>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
            viewBox="0 0 24 24"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-grid"
            viewBox="0 0 24 24"
          >
            <rect x="3" y="3" width="7" height="7"></rect>
            <rect x="14" y="3" width="7" height="7"></rect>
            <rect x="14" y="14" width="7" height="7"></rect>
            <rect x="3" y="14" width="7" height="7"></rect>
          </svg>
        )}
      </div>

      <Box className="NavMainContainer">
        <nav
          className={`vertical-menu ${menuOpen ? "open" : ""}`}
          ref={menuRef}
        >
          <ul>
            <li>
              <a
                href="/user/dashboard"
                className={
                  location.pathname === "/user/dashboard" ? "active" : ""
                }
              >
                <img src={DNA} alt="DNA" />
                <span> DNA</span>
              </a>
            </li>
            <li>
              <a
                href={PATH_USER.referral}
                className={
                  location.pathname === PATH_USER.referral ? "active" : ""
                }
              >
                <img src={Referral} alt="Referral" />
                <span>Referral </span>
              </a>
            </li>
            <li>
              <a
                href={PATH_USER.bountyProgram.root}
                className={
                  location.pathname === PATH_USER.bountyProgram.root
                    ? "active"
                    : ""
                }
              >
                <img src={Bounty} alt="Bounty Program" />
                <span>Bounty Program </span>
              </a>
            </li>
            <li>
              <a
                href={PATH_USER.financial.fundsTransfer}
                className={
                  location.pathname === PATH_USER.financial.fundsTransfer
                    ? "active"
                    : ""
                }
              >
                <img src={FundTransfer} alt="Fund Transfer" />
                <span>Fund Transfer </span>
              </a>
            </li>

            <li id="ReportSub" className="relative">
              <a>
                <img src={Report} alt="Report" />
                <span>Report</span>
              </a>
              <span className="afterinfo"></span>

              <ul className="SubMenuItemBox">
                <li>
                  <a href={PATH_USER.incomeReport.root}>
                    <span>Revenue Report </span>
                  </a>
                </li>
                <li>
                  <a href={PATH_USER.withdrawalReport.root}>
                    <span>Withdrawal Report </span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span>Bounty Report </span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span>Auction Report </span>
                  </a>
                </li>
              </ul>
            </li>

            {/* <a href="#">
            <img src={Shopping} alt="Shopping" />
            <span>Shopping</span>
          </a> */}
            <li>
              <a
                href={PATH_USER.financial.payout}
                className={
                  location.pathname === PATH_USER.financial.payout
                    ? "active"
                    : ""
                }
              >
                <img src={Withdrawal} alt="Withdrawal" />
                <span>Withdrawal</span>
              </a>
            </li>
            <li>
              <a
                href={PATH_USER.helpCenter.createTicket.view}
                className={
                  location.pathname === "/user/help-center/create-ticket/open"
                    ? "active"
                    : ""
                }
              >
                <img src={Support} alt="Support" />
                <span>Support </span>
              </a>
            </li>
          </ul>
        </nav>
      </Box>
    </Box>
  );
};

export default UserVerticalNavbar;
